import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import Nav from 'react-bootstrap/Nav';
import { addCart } from "./store";
import { useDispatch } from "react-redux";

function DetailComponent(props){
    let [popup, setPopup] = useState(true)
    let [quantity, setQuantity] = useState(0)
    let [warning, setWarning] = useState(false)
    let [tab, setTab] = useState(0)
    let [fade, setFade] = useState("")
    let dispatch = useDispatch();

    let {id} = useParams();

    useEffect(() => {
        setTimeout(() => {setPopup(false)}, 2000)

        // return ( ) => { // clean-up function : useEffect 전에 실행 됨 용도 : 기존 요청, 기존 타이머 제거
        // }
    }, [])

    useEffect(() => {
        if (isNaN(quantity)){
            setWarning(true)
        }else{
            setWarning(false)
        }

    },[quantity])

    useEffect(()=>{
        setTimeout(() => {setFade("end")},100)
        return ()=>{
            setFade("")
        }
    }, [id])

    
    const filteredData = props.shoes.filter(currentItem => currentItem.id === Number(id))
    return(
    <div className={"container start " + fade}>

        {
            popup ?  <div className = "alert alert-warning">2초이내 구매시 할인</div>  : null
        }
        <div className="row">
        <div className="col-md-6">
            <img src={props.shoesImgPath[id]} alt = "이미지 로드 불가" width="100%" />
        </div>
        <div className="col-md-6">
            
            <h4 className="pt-5">{filteredData[0].title}</h4>
            <p>{filteredData[0].content}</p>
            <p>{filteredData[0].price}원</p>
            <div>
                <input placeholder="수량 입력란" onChange={
                    (e) =>{
                        let value = e.target.value
                        setQuantity(value)
                    }
                }/>

            </div>
            {
                warning ? <div style = {{"background" : "red", "color" : "white"}}>경고 : 숫자만 입력 가능</div> : null
            }
            <button className="btn btn-danger" onClick={() => {
                // 장바구니 담기 로직
                console.log("장바구니 담기")
                dispatch(addCart({id : id, title : filteredData[0].title , quantity : quantity}))
                setQuantity("");
            }}>장바구니 담기</button> 
        </div>
        </div>

        <Nav fill variant="tabs" defaultActiveKey="link0">
            <Nav.Item>
                <Nav.Link eventKey="link0" onClick = {() => { setTab(0)}}>탭1</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="link1" onClick = {() => { setTab(1)}}>탭2</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="link2" onClick = {() => { setTab(2)}}>탭3</Nav.Link>
            </Nav.Item>
        </Nav>
        <TabContent tab = {tab} shoes = {props.shoes}/>
        </div> 
    )
}

function TabContent(props){
    let [fade, setFade] = useState("")

    useEffect(()=>{
        let a = setTimeout(() => {setFade("end")}, 100);

        return()=> {
            clearTimeout(a)
            setFade("")
        }
    }, [props.tab])

    return(
        <div className = {"start " + fade}>
        {
            [<div>{props.shoes[0].title}</div>,<div>내용1</div>, <div>내용2</div>][props.tab]
        }
        </div>
    
    )
    
}
export default DetailComponent