import './App.css';
import {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


import FirstImagePath from './main-shoes1.png';
import SecondImagePath from './main-shoes2.png';
import ThirdImagePath from './main-shoes3.png';

import DetailComponent from './Detail.js';
import Cart from './Cart.js';
import data from './data.js';



import {Routes, Route, useNavigate, Outlet} from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
function App() {
  
  useEffect(()=>{
    localStorage.setItem("watched", JSON.stringify([]))
  }, [])

  
  let [shoes, setShoes] = useState(data);
  let [shoesImgPath, setShoesImgPath] = useState([FirstImagePath,SecondImagePath,ThirdImagePath, 
                                                  "https://codingapple1.github.io/shop/shoes4.jpg", "https://codingapple1.github.io/shop/shoes5.jpg", "https://codingapple1.github.io/shop/shoes6.jpg",
                                                  "https://codingapple1.github.io/shop/shoes7.jpg", "https://codingapple1.github.io/shop/shoes8.jpg", "https://codingapple1.github.io/shop/shoes9.jpg"]

                                                );
  let navigate = useNavigate();
  let [page, setPage] = useState(2);
  let [click, setClicked] = useState(0);
  let [watched, setWatched] = useState(false);

  
  
  let result = useQuery('query', () => {
      return axios.get("https://codingapple1.github.io/userdata.json")
      .then((response) => { return response.data})
    })

  return (
    
    <div className="App">
      
      
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand onClick={()=>{ navigate("/")}} style = { {'fontWeight' : 'bold'}}>PBJ</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={()=>{ navigate("/new")}}>NEW</Nav.Link>
            <Nav.Link onClick={()=>{ navigate("/all")}}>ALL</Nav.Link>
            <Nav.Link onClick={()=>{ navigate("/cart")}}>CART</Nav.Link>
          </Nav>
        </Container>
        <Nav className = "me-auto">
          { result.isLoading ? '로딩중' : result.data.name}
        </Nav>
      </Navbar>

      <Routes>
        <Route path = "/" element = { <MainComponent shoes = {shoes} shoesImgPath = {shoesImgPath} setShoes = {setShoes} page = {page} setPage = {setPage} click = {click} setClicked = {setClicked} watched = {watched} setWatched = {setWatched}/>} />
        <Route path = "/detail/:id" element = {<DetailComponent shoes = {shoes} shoesImgPath = {shoesImgPath}/>} />
        
        <Route path = "*" element = {<div>404 : pageNotFount</div>} />
        <Route path = "/cart" element = {<Cart/>}/>
      </Routes>
      
      
    </div>
  );
}

export default App;


function Event(){
  return(
    <div>
      <h4>오늘의 이벤트</h4>
      <Outlet></Outlet>
    </div>
  )
}

function WatchedRecently(){
  return(
      <div className='recently-box'>
          <div className = 'recently-header'>최근 본 상품</div>
          {
            JSON.parse(localStorage.getItem("watched")).map(
              (currentItem) => {
                return (
                  <div className = 'recently-body'>{currentItem}</div>
                )
              }
            )

          }
          
      </div>
  )
}
function MainComponent(props){
  const fetchData = () => {
    axios.get("https://codingapple1.github.io/shop/data" + props.page +".json")
      .then(response => {
        const newData = [...props.shoes, ...response.data]
        props.setShoes(newData)
      }) 
      .catch(error => {
        console.log(error)
      })
      
  }



  return(

    
    <>
      <div className = 'mainBox'>
        {
          props.watched ? <WatchedRecently/> : null
        }
          </div>
      <div className = 'main-banner'>
        New Arrival
      </div>
      <div>
        <Container>
          <Row>
            {
              props.shoes.map((currentItem, i) => {
                return(
                  <Col>
                    <Card shoesImg = {props.shoesImgPath[i]} currentItem = {currentItem} productId = {i} watched = {props.watched} setWatched = {props.setWatched}></Card>
                  </Col>
                )
              })
            }
            
          </Row>
          <div>
        {
          props.click < 2 ? <Button variant="secondary" onClick={()=>{
            props.setPage(props.page + 1);
            props.setClicked(props.click + 1);
            fetchData();
            }
            
            }>더보기</Button> : null
        }
        
      </div>
        </Container> 
      </div>
    </>
  )
}
function Card(props){

  let navigate = useNavigate();
  const nevigatePath = "/detail/"+ props.productId
  return(
    <div>
      <img src  = {props.shoesImg} width = '400px' onClick = {()=>{
        const storage = JSON.parse(localStorage.getItem("watched"));
        console.log(storage)
        if(storage.length < 5){
          storage.unshift(props.currentItem.title);
        }else{
          storage.pop()
          storage.unshift(props.currentItem.title);
        }
        const set = new Set(storage);
        const uniqueArr = [...set];
        localStorage.setItem("watched", JSON.stringify(uniqueArr));
        if(!props.watched){
          props.setWatched(true)
        }
        navigate(nevigatePath)
      }}></img>
      <h5>{props.currentItem.title}</h5>
      <h6>{props.currentItem.content}</h6>
      <p style={{'fontSize' : 20, 'fontWeight': 'bold'}}>{props.currentItem.price}원</p>
    </div>
  )
}



