import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { removeCart, increaseQuantity } from './store';
import Button from 'react-bootstrap/Button';


function Cart(){

    let storedData = useSelector((state)=>{return state})
    const cartData = storedData.cartData
    let dispatch = useDispatch()




    return(
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>상품명</th>
                        <th>수량</th>
                        <th>변경하기</th>
                    </tr>
                </thead>
                <tbody>

                {
                    cartData.map((item)=>{
                        return(
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.count}</td>
                            <td>
                                <Button variant="success" onClick={() => {
                                dispatch(increaseQuantity(item.id))
                            }}>+</Button>
                                <Button variant="danger" onClick = {() => {
                                    dispatch(removeCart(item.id))
                                }}>
                                    삭제
                                </Button>
                            </td>
                        </tr>

                        )
                    })
                }
                    
                </tbody>
                </Table>
        </div>
    )
}

export default Cart;