import { configureStore, createSlice } from '@reduxjs/toolkit'


let user = createSlice({
    name : 'userName',
    initialState : "kim",

    reducers : {
        changeName(state){
            return "john" + state
        }
        
    }

})

export const { changeName } = user.actions


let cartData = createSlice({
    name : 'cartData',
    initialState : [
        
    ] ,

    reducers : {

        addCart(state, action){
            const id = action.payload.id;
            const title = action.payload.title;
            const quantity = action.payload.quantity;
            /// 배열의 id값을 가지고 이미 있는 값이면 추가해주기
            /// 아니면 push하도록
            const idList = state.map(item => item.id)
            if(idList.includes(id)){
                const index = state.findIndex(state => state.id === id)
                state[index].count += Number(quantity);
            }else{
                state.push({id : id, name : title, count : Number(quantity)});
            }
            
            return state;
        },

        removeCart(state, action){
            const id = action.payload
            const index = state.findIndex((state) => { return state.id === id})
            state.splice(index, 1)
            return state;
        },

        increaseQuantity(state, action){
            const id = action.payload;
            const index = state.findIndex(state => state.id === id)
            state[index].count +=1;
            return state; 
        }
    }
})

export const {addCart, removeCart, increaseQuantity} = cartData.actions

export default configureStore({
    reducer: {
        user : user.reducer,
        cartData : cartData.reducer
    }
}) 